import { useQuery } from '@tanstack/react-query';

import { getAiAssistantHistory, getAiAssistantQuotas, getAiAssistantStatus } from '@api';

export const aiAssistantKeys = {
  status: ['aiAssistantStatus'],
  quotas: ['aiAssistantQuotas'],
  history: ['aiAssistantHistory'],
};

export const useAiAssistantStatusQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: aiAssistantKeys.status,
    queryFn: () => getAiAssistantStatus(),
    enabled,
    staleTime: 0,
  });
};

export const useAiAssistantQuotasQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: aiAssistantKeys.quotas,
    queryFn: () => getAiAssistantQuotas(),
    enabled,
  });
};

export const useAiAssistantHistoryQuery = ({
  enabled,
  staleTime,
}: {
  enabled: boolean;
  staleTime?: number;
}) => {
  return useQuery({
    queryKey: aiAssistantKeys.history,
    queryFn: () => getAiAssistantHistory(),
    enabled,
    staleTime,
  });
};
